import React from "react";
import { useNavigate } from "react-router-dom";

import CardCategory from "../Layout/CardCategory";
import css from "../Home.module.css";
import Head from "../../Utils/Head";

export const School = () => {
  const navigate = useNavigate();

  return (
    <div className={css.content}>
      <Head title="Enem" description="Enem - Mairimed" />
      <CardCategory
        icon={"route"}
        title={"Mindmaps"}
        onClick={() => navigate("/enem/mindmaps")}
      />
      {/* <CardCategory
        icon={"quiz"}
        title={"Enem"}
        onClick={() => navigate("/enem/provas")}
      /> */}
    </div>
  );
};
