import React from "react";
import { Link, useLocation } from "react-router-dom";
import css from "./SidebarCollapsed.module.css";

export const SidebarCollapsed = () => {
  const { pathname } = useLocation();

  return (
    <div className={css.sidebar}>
      <div className={css.sidebarWrapper}>
        <div className={css.sidebarMenu}>
          <h3 className={css.sidebarTitle}>Discover</h3>
          <ul className={css.sidebarList}>
            {pathname === "/" ? (
              <Link to="/" className={css.link}>
                <li className={`${css.sidebarListItem} ${css.active}`}>
                  <i className={`material-icons ${css.sidebarIcon}`}>
                    local_library
                  </i>
                </li>
              </Link>
            ) : (
              <Link to="/" className={css.link}>
                <li className={`${css.sidebarListItem}`}>
                  <i className={`material-icons ${css.sidebarIcon}`}>
                    local_library
                  </i>
                </li>
              </Link>
            )}
            {pathname === "/cid10" ? (
              <Link to="/cid10" className={css.link}>
                <li className={`${css.sidebarListItem} ${css.active}`}>
                  <i className={`material-icons ${css.sidebarIcon}`}>
                    medical_information
                  </i>
                </li>
              </Link>
            ) : (
              <Link to="/cid10" className={css.link}>
                <li className={css.sidebarListItem}>
                  <i className={`material-icons ${css.sidebarIcon}`}>
                    medical_information
                  </i>
                </li>
              </Link>
            )}

            {/* <a href="https://mairimed.com/login/" className={css.link}>
              <li className={css.sidebarListItemDisabled}>
                <i className={`material-icons ${css.sidebarIconDisabled}`}>
                  explore
                </i>
              </li>
            </a> */}
          </ul>
        </div>

        <div className={css.sidebarMenu}>
          <h3 className={css.sidebarTitle}>My Study</h3>
          <ul className={css.sidebarList}>
            <a href="https://mairimed.com/login/" className={css.link}>
              <li className={css.sidebarListItemDisabled}>
                <i className={`material-icons ${css.sidebarIconDisabled}`}>
                  route
                </i>
              </li>
            </a>

            <a href="https://mairimed.com/login/" className={css.link}>
              <li className={css.sidebarListItemDisabled}>
                <i className={`material-icons ${css.sidebarIconDisabled}`}>
                  layers
                </i>
              </li>
            </a>
            <a href="https://mairimed.com/login/" className={css.link}>
              <li className={css.sidebarListItemDisabled}>
                <i className={`material-icons ${css.sidebarIconDisabled}`}>
                  quiz
                </i>
              </li>
            </a>
          </ul>
        </div>

        <div className={`copyright ${css.copyright} `}>
          <p>mairimed.com</p>
          <p>©2023</p>
        </div>
      </div>
    </div>
  );
};
