import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { getMindmapFirebase } from "../../Utils/firebaseMindmaps";
import Head from "../../Utils/Head";
import Chart from "./Chart";

export const ViewMindmap = () => {
  const params = useParams();
  const [mindmap, setMindmap] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMindmapFirebase(params.id).then(function (result) {
      setMindmap(result);
      let mapTags = [];
      result.tag1 !== "" && mapTags.push(result.tag1);
      result.tag2 !== "" && mapTags.push(result.tag2);
      result.tag3 !== "" && mapTags.push(result.tag3);
      setLoading(false);
    });
  }, [params.id]);

  const toggleMoveMode = () => {
    const moveMode = !mindmap.moveMode;
    const newMindmap = { ...mindmap };
    newMindmap.moveMode = moveMode;
    setMindmap(newMindmap);
  };

  const ZOOM_FACTOR = 1.1;

  const zoomIn = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const zoom = mindmap.zoom * ZOOM_FACTOR;
    const newMindmap = { ...mindmap };
    newMindmap.zoom = zoom;
    setMindmap(newMindmap);
  };

  const zoomOut = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const zoom = mindmap.zoom / ZOOM_FACTOR;
    const newMindmap = { ...mindmap };
    newMindmap.zoom = zoom;
    setMindmap(newMindmap);
  };

  const zoomReset = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const zoom = 0.2;
    const x = 0;
    const y = 0;
    const newMindmap = { ...mindmap };
    newMindmap.zoom = zoom;
    newMindmap.x = x;
    newMindmap.y = y;
    setMindmap(newMindmap);
  };

  const onMouseDown = (e) => {
    if (!mindmap.moveMode) {
      return;
    }
    setIsDragging(true);
    const newStartX = mindmap.x * 1 + e.clientX;
    setStartX(newStartX);
    const newStartY = mindmap.y * 1 + e.clientY;
    setStartY(newStartY);
  };

  const onMouseMove = (e) => {
    if (!isDragging) {
      return;
    }
    e.preventDefault();
    const newMindmap = { ...mindmap };
    const x = (newMindmap.x + startX - e.clientX) * 0.5;
    const y = (newMindmap.y + startY - e.clientY) * 0.5;
    newMindmap.x = x;
    newMindmap.y = y;
    setMindmap(newMindmap);
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const handleSelected = (id) => {
    console.log(id);
  };

  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <Head
          title={mindmap.title}
          description={`${mindmap.title} - Mairimed`}
        />
        <div>{mindmap.title}</div>
        <Chart
          mindmapName={mindmap.title}
          mindmapId={mindmap.id}
          id={mindmap.id}
          list={mindmap.list}
          onClick={handleSelected}
          x={mindmap.x}
          y={mindmap.y}
          width={mindmap.width}
          height={mindmap.height}
          zoom={mindmap.zoom}
          onZoomIn={zoomIn}
          onZoomOut={zoomOut}
          onZoomReset={zoomReset}
          onToggleMoveMode={toggleMoveMode}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
        />
      </>
    );
  }
};
