import css from "./leftmenu.module.css";
import { useNavigate } from "react-router-dom";

import { Sidebar } from "../Sidebar/Sidebar";
import Toolbar from "./Toolbar";
import { SidebarCollapsed } from "../Sidebar/SidebarCollapsed";

export const LeftMenu = (props) => {
  const navigate = useNavigate();

  const list = [
    { name: "home", onClick: () => navigate("/") },
    { name: "calendar", onClick: () => navigate("/calendar") },
  ];

  let className = css.container;
  if (!props.isMenuVisible) {
    className = css.collapsed;
  }

  return (
    <div className={className} onMouseLeave={props.onMouseLeave}>
      <Toolbar list={list} type="primary" location={["vertical"]} />
      {props.isMenuVisible ? <Sidebar /> : <SidebarCollapsed />}
    </div>
  );
};
