import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";

import { db } from "../../firebaseConfig";
import HTMLReactParser from "html-react-parser";
import Head from "../../Utils/Head";

export const CicloClinicoArticle = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState([]);

  useEffect(() => {
    const getSpeciality = async () => {
      const docRef = doc(db, "articles", params.speciality);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log(params.article + " loaded");
        return docSnap.data();
      } else {
        console.log("No such document");
      }
    };

    getSpeciality().then(function (result) {
      if (result) {
        setArticle(result[params.article]);
        setLoading(true);
      }
    });
  }, [params.article, params.speciality]);

  function makeTitle(slug) {
    var words = slug.split("-");

    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    return words.join(" ");
  }

  if (!loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <Head
          title={makeTitle(params.article)}
          description={makeTitle(params.article) + " - Mairimed"}
        />
        <h2>{makeTitle(params.article)}</h2>
        {HTMLReactParser(article)}
      </div>
    );
  }
};
