import React from "react";
import { Link, useLocation } from "react-router-dom";

import css from "./Sidebar.module.css";

export const Sidebar = () => {
  const { pathname } = useLocation();

  return (
    <div className={css.sidebar}>
      <div className={css.sidebarWrapper}>
        <div className={css.sidebarMenu}>
          <h3 className={css.sidebarTitle}>Discover</h3>

          <ul className={css.sidebarList}>
            {pathname === "/" ? (
              <Link to="/" className={css.link}>
                <li className={`${css.sidebarListItem} ${css.active}`}>
                  <i className={`material-icons ${css.sidebarIcon}`}>
                    local_library
                  </i>
                  Study
                </li>
              </Link>
            ) : (
              <Link to="/" className={css.link}>
                <li className={css.sidebarListItem}>
                  <i className={`material-icons ${css.sidebarIcon}`}>
                    local_library
                  </i>
                  Study
                </li>
              </Link>
            )}
            {pathname === "/cid10" ? (
              <Link to="/cid10" className={css.link}>
                <li className={`${css.sidebarListItem} ${css.active}`}>
                  <i className={`material-icons ${css.sidebarIcon}`}>
                    medical_information
                  </i>
                  CID-10
                </li>
              </Link>
            ) : (
              <Link to="/cid10" className={css.link}>
                <li className={css.sidebarListItem}>
                  <i className={`material-icons ${css.sidebarIcon}`}>
                    medical_information
                  </i>
                  CID-10
                </li>
              </Link>
            )}
            {/* <a href="https://mairimed.com/login/" className={css.link}>
              <li className={`${css.sidebarListItemDisabled}`}>
                <i className={`material-icons ${css.sidebarIconDisabled}`}>
                  explore
                </i>
                Discover
              </li>
            </a> */}
          </ul>
        </div>

        <div className={css.sidebarMenu}>
          <h3 className={css.sidebarTitle}>My Study</h3>
          <ul className={css.sidebarList}>
            <a href="https://mairimed.com/login/" className={css.link}>
              <li className={css.sidebarListItemDisabled}>
                <i className={`material-icons ${css.sidebarIconDisabled}`}>
                  route
                </i>
                Mindmaps
              </li>
            </a>
            <a href="https://mairimed.com/login/" className={css.link}>
              <li className={css.sidebarListItemDisabled}>
                <i className={`material-icons ${css.sidebarIconDisabled}`}>
                  layers
                </i>
                Flashcards
              </li>
            </a>
            <a href="https://mairimed.com/login/" className={css.link}>
              <li className={css.sidebarListItemDisabled}>
                <i className={`material-icons ${css.sidebarIconDisabled}`}>
                  quiz
                </i>
                Quiz
              </li>
            </a>
          </ul>
        </div>

        <div className={`copyright ${css.copyright} `}>mairimed.com © 2023</div>
      </div>
    </div>
  );
};
