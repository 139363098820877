import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import css from "./ICD10.module.css";
import { db } from "../../firebaseConfig";

export const ICD10 = () => {
  const [icdList, setIcdList] = useState([]);
  const [letterList, setLetterList] = useState([]);
  const [letter, setLetter] = useState("a");
  const alphabet = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  useEffect(() => {
    const getCID10 = async () => {
      const docRef = doc(db, "icd", "cid10");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("cicloBasico loaded");
        return docSnap.data();
      } else {
        console.log("No such document");
      }
    };

    getCID10().then(function (result) {
      setIcdList(result.cid10);
      setLetterList(result.cid10.filter((cid) => cid.slug[0] === "a"));
    });
  }, []);

  useEffect(() => {
    setLetterList(icdList.filter((cid) => cid.slug[0] === letter));
  }, [letter, icdList]);

  if (icdList[0]) {
    return (
      <div>
        <div className={css.alphabet}>
          {alphabet.map((lett, i) => (
            <span key={i} onClick={() => setLetter(lett)}>
              {lett}
            </span>
          ))}
        </div>
        <div className={css.letter}>{letter}</div>
        {alphabet.map((lett, i) => (
          <React.Fragment key={i}>
            {letter === lett &&
              letterList.map((icd) => (
                <div key={icd.slug} className={css.letterItems}>
                  <Link to={"/cid10/" + icd.slug}>
                    {icd.cod + " - " + icd.title}
                  </Link>
                </div>
              ))}
          </React.Fragment>
        ))}
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
