import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";

import "./App.css";
import "./theme.css";
import { useGoogleAnalytics } from "./Hooks/useGoogleAnalytics";
import { Header } from "./Components/Layout/Header";
import { Footer } from "./Components/Footer";
import { NotFound } from "./Components/NotFound";
import { Home } from "./Components/Home";
import { Content } from "./Components/Layout/Content";
import { LeftMenu } from "./Components/Layout/LeftMenu";
import { CicloBasico } from "./Components/Med/CicloBasico";
import { CicloBasicoArticle } from "./Components/Med/CicloBasicoArticle";
import { CicloClinico } from "./Components/Med/CicloClinico";
import { CicloClinicoSpeciality } from "./Components/Med/CicloClinicoSpeciality";
import { CicloClinicoArticle } from "./Components/Med/CicloClinicoArticle";
import { School } from "./Components/School/School";
import { Enem } from "./Components/School/Enem";
import { SchoolMaps } from "./Components/School/SchoolMaps";
import { SchoolMapsSubject } from "./Components/School/SchoolMapsSubject";
import { ViewMindmap } from "./Components/Mindmaps/ViewMindmap";
import { ICD10 } from "./Components/Med/ICD10";
import { ICD10Details } from "./Components/Med/ICD10Details";

function App() {
  useGoogleAnalytics();
  const [isMenuVisible, setIsMenuVisible] = React.useState(true);

  const toggleMenu = (e) => {
    setIsMenuVisible(!isMenuVisible);
  };
  // const hideMenu = () => {
  //   setIsMenuVisible(false);
  // };

  const SidebarLayout = () => (
    <>
      <Header onMenuClick={toggleMenu} />
      <LeftMenu isMenuVisible={isMenuVisible} />
      <Content
        isMenuVisible={isMenuVisible}
        setIsMenuVisible={setIsMenuVisible}
      />
    </>
  );

  const FooterLayout = () => (
    <div className="">
      <div className="">
        <Outlet />
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );

  return (
    <>
      <Routes>
        <Route element={<SidebarLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="study/mindmap/:id" element={<ViewMindmap />} />
          <Route path="enem/" element={<School />} />
          <Route path="enem/mindmaps" element={<SchoolMaps />} />
          <Route
            path="enem/mindmaps/:subject"
            element={<SchoolMapsSubject />}
          />
          <Route path="enem/provas" element={<Enem />} />
          <Route path="ciclobasico/" element={<CicloBasico />} />
          <Route path="ciclobasico/:article" element={<CicloBasicoArticle />} />
          <Route path="cicloclinico/" element={<CicloClinico />} />
          <Route
            path="cicloclinico/:speciality"
            element={<CicloClinicoSpeciality />}
          />
          <Route
            path="cicloclinico/:speciality/:article"
            element={<CicloClinicoArticle />}
          />
          <Route path="/cid10" element={<ICD10 />} />
          <Route path="/cid10/:slug" element={<ICD10Details />} />
        </Route>
        <Route element={<FooterLayout />}></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
