import {
  doc,
  setDoc,
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

export const getMindmapsFirebase = async () => {
  const docRef = collection(db, "mindmaps");
  const docSnap = await getDocs(docRef);
  if (docSnap) {
    console.log("all mindmaps loaded");
    return docSnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No such document");
  }
};

export const getTagMindmapsFirebase = async (tag) => {
  const resultDecks = [];
  const docRef = collection(db, "mindmaps");
  const q1 = query(
    docRef,
    where("tag1.title", "==", tag),
    where("hide", "==", false)
  );
  const q2 = query(
    docRef,
    where("tag2.title", "==", tag),
    where("hide", "==", false)
  );
  const q3 = query(
    docRef,
    where("tag3.title", "==", tag),
    where("hide", "==", false)
  );
  const querySnap1 = await getDocs(q1);
  const querySnap2 = await getDocs(q2);
  const querySnap3 = await getDocs(q3);
  if (querySnap1) {
    console.log(`mindmaps tag1=${tag} loaded`);
    let docs = querySnap1.docs.map((doc) => ({ ...doc.data() }));
    docs.length > 0 && docs.forEach((doc) => resultDecks.push(doc));
  }
  if (querySnap2) {
    console.log(`mindmaps tag2=${tag} loaded`);
    let docs = querySnap2.docs.map((doc) => ({ ...doc.data() }));
    docs.length > 0 && docs.forEach((doc) => resultDecks.push(doc));
  }
  if (querySnap3) {
    console.log(`mindmaps tag3=${tag} loaded`);
    let docs = querySnap3.docs.map((doc) => ({ ...doc.data() }));
    docs.length > 0 && docs.forEach((doc) => resultDecks.push(doc));
  } else {
    console.log("No such document");
  }
  return resultDecks;
};

export const getMindmapsSnippetsFirebase = async (user) => {
  const docRef = doc(db, "users", user.uid, "snippets", "mindmapsSnippets");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log("mindmapsSnippets loaded");
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const getUserMindmapsFirebase = async (user) => {
  const docRef = collection(db, "mindmaps");
  const q = query(docRef, where("userId", "==", user.uid));
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log("user mindmaps loaded");
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No such document");
  }
};

export const getMindmapFirebase = async (id) => {
  const docRef = doc(db, "mindmaps", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`mindmap ${id} loaded`);
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const createMindmapFirebase = async (
  user,
  mindmap,
  mindmapsSnippets
) => {
  await setDoc(doc(db, "mindmaps", mindmap.id), {
    id: mindmap.id,
    hide: mindmap.hide,
    userId: mindmap.userId,
    userName: mindmap.userName,
    title: mindmap.title,
    comment: mindmap.comment,
    reference: mindmap.reference,
    list: mindmap.list,
    level: mindmap.level,
    tag1: mindmap.tag1,
    tag2: mindmap.tag2,
    tag3: mindmap.tag3,
    prev: mindmap.prev,
    next: mindmap.next,
    parentId: mindmap.parentId,
    x: mindmap.x,
    y: mindmap.y,
    width: mindmap.width,
    height: mindmap.height,
    zoom: mindmap.zoom,
    moveMode: mindmap.moveMode,
  });

  await setDoc(doc(db, "users", user.uid, "snippets", "mindmapsSnippets"), {
    mindmapsSnippets,
  });
  console.log("mindmap created");
};

export const updateMindmapFirebase = async (
  user,
  mindmap,
  mindmapsSnippets
) => {
  await updateDoc(doc(db, "mindmaps", mindmap.id), {
    id: mindmap.id,
    hide: mindmap.hide,
    userId: mindmap.userId,
    userName: mindmap.userName,
    title: mindmap.title,
    comment: mindmap.comment,
    reference: mindmap.reference,
    list: mindmap.list,
    level: mindmap.level,
    tag1: mindmap.tag1,
    tag2: mindmap.tag2,
    tag3: mindmap.tag3,
    prev: mindmap.prev,
    next: mindmap.next,
    parentId: mindmap.parentId,
    x: mindmap.x,
    y: mindmap.y,
    width: mindmap.width,
    height: mindmap.height,
    zoom: mindmap.zoom,
    moveMode: mindmap.moveMode,
  });

  await setDoc(doc(db, "users", user.uid, "snippets", "mindmapsSnippets"), {
    mindmapsSnippets,
  });
  console.log(`mindmap ${mindmap.title} updated`);
};

export const deleteMindmapFirebase = async (
  user,
  mindmap,
  mindmapsSnippets
) => {
  await deleteDoc(doc(db, "mindmaps", mindmap.id));
  await setDoc(doc(db, "users", user.uid, "snippets", "mindmapsSnippets"), {
    mindmapsSnippets,
  });
  console.log(`mindmap ${mindmap.title} deleted`);
};
