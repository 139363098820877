import { initializeApp } from "firebase/app";
import {
  getAuth,
  connectAuthEmulator,
  GoogleAuthProvider,
} from "firebase/auth";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyARSs0bP4RA546_qzao4O7GlqLGJNrM5nU",
  authDomain: "mairimed-7a202.firebaseapp.com",
  projectId: "mairimed-7a202",
  storageBucket: "mairimed-7a202.appspot.com",
  messagingSenderId: "412921812475",
  appId: "1:412921812475:web:0fa84650588120fffea75e",
  measurementId: "G-N77S2XYDV0",
};

const app = initializeApp(firebaseConfig);

// if (window.location.hostname === "localhost") {
//   var authFirebase = getAuth();
//   connectAuthEmulator(authFirebase, "http://localhost:9099");
//   var dbFirebase = getFirestore();
//   connectFirestoreEmulator(dbFirebase, "localhost", 8080);
//   var storageFirebase = getStorage();
//   connectStorageEmulator(storageFirebase, "localhost", 9199);
// } else {
//   authFirebase = getAuth(app);
//   dbFirebase = getFirestore(app);
//   storageFirebase = getStorage(app);
// }

let authFirebase = getAuth(app);
let dbFirebase = getFirestore(app);
let storageFirebase = getStorage(app);

export const storage = storageFirebase;
export const auth = authFirebase;
export const db = dbFirebase;
export const googleProvider = new GoogleAuthProvider();
