import React from "react";
import { Link } from "react-router-dom";

import styles from "./header.module.css";
import { ReactComponent as Logo } from "../../assets/mairimed-logo.svg";
import IconButton from "./IconButton";

export const Header = (props) => {
  return (
    <div className={styles.topbar}>
      <div className={styles.topbarWrapper}>
        <div className={styles.topLeft}>
          <div className={styles.logo}>
            <IconButton name="menu" onClick={(e) => props.onMenuClick(e)} />
            <Link
              className={styles.logo}
              to="/ciclobasico"
              aria-label="Mairimed - Home"
            >
              <Logo /> Mairimed
            </Link>
          </div>
        </div>
        <div className={styles.topRight}>
          <div className={styles.avatar}>
            <div className={styles.menuContainer}>
              <a href="https://mairimed.com/login/">
                <button className="defaultButton">Sign in</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
